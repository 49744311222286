/* Change this file to get your personal Porfolio */

// Website related settings

const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Abhay's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Abhay Pratap Singh Portfolio",
    type: "website",
    // url: "http://ashutoshhathidara.com/",
  },
};

//Home Page

const greeting = {
  title: "Abhay Pratap Singh",
  logo_name: "Abhay Pratap",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1FUstNMTwqbBXwRbLM-bNuOusSHNin57G/view?usp=sharing",
  portfolio_repository: "https://github.com/AbhayPratap7",
  githubProfile: "https://github.com/AbhayPratap7",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/AbhayPratap7",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/abhay-pratap-singh-sanchora-6b11b4293/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:singhabhaypratap72003@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  
  {
    name: "Instagram",
    link: "https://www.instagram.com/abhay_pratap._.singh/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Manipal University Jaipur ",
      subtitle: "B.Tech. in Computer Engineering (AIML)",
      logo_path: "jaipur.png",
      
      duration: "2021 - 2025",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I Voluntered many events as a leader in my college time as of Tech Start-up Event by AIC MUJ , Hackathon by Digibox and many more",
        "⚡ My degree for B.Tech. in Computer Engineering (AIML) is still going on ",
      ],
      website_link: "https://jaipur.manipal.edu",
    },
    
    {
      title: "Kendriya Vidyalaya Mandsaur [Schooling]",
      subtitle: "Science Student [PCM]",
      logo_path: "KV.jpeg",
      duration: "2021",
      descriptions: [
        "⚡ I Scored 93% in my CBSE 12th Board Exam in 2021 ",
        "⚡ Apart from this, I was The Captian of my House in my School and under my guidance and hard work of team members we secured many Prizes in the name of my House ",
        "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
      ],
      website_link: "https://kvsangathan.nic.in",
    },
    {
      title: "Kendriya Vidyalaya Mandsaur [Schooling]",
      subtitle: "Class 10th CBSE",
      logo_path: "KV.jpeg",
      duration: "2019",
      descriptions: [
        "⚡ I Scored 85% in my CBSE 10th Board Exam in 2019 ",
        "⚡ Student Council Member: Actively participated in organizing and leading school events, fostering a sense of community and teamwork among students. ",
        "⚡ Robotics Team Leader: Led the school robotics team to regional competitions, designing and building robots for various challenges.",
      ],
      website_link: "https://kvsangathan.nic.in",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Artificial Intelligence: Ethics & Societal Challenges",
      logo_path: "course.png",
      certificate_link:
        "https://drive.google.com/file/d/1d7BD59CYr-6lzQFVVT_2y_Qm56U2emuG/view?usp=sharing",
      
      color_code: "#8C151599",
    },
    {
      title: "Web Development | HTMLI CSS I JavaScript | jQuery | NodeJS",
      subtitle: "Udemy",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-a969e768-b4cd-4de3-bde3-a87e21e9fcc9/",
      
      color_code: "#00000099",
    },
    {
      title: "Networking Essentials",
      subtitle: "Cisco",
      logo_path: "cisco.png",
      certificate_link:
        "https://drive.google.com/file/d/11W7HiUcsqgRYyH7a1IY-60_yFF6qJarY/view?usp=sharing",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "CCNAv7: Enterprise Networking, Security, and Automation",
      subtitle: "Cisco",
      logo_path: "cisco.png",
      certificate_link:
        "https://drive.google.com/file/d/1nNI3S_HFXU_pvR_-dBOnwBhqwbv4mNJ0/view?usp=sharing",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Database Foundations",
      subtitle: "Oracle Academy",
      logo_path: "oracle.png",
      certificate_link:
        "https://drive.google.com/file/d/1Yf7TiMd2CccmWtriu7YgfvUuLtYobtLk/view?usp=sharing",
     
      color_code: "#D83B0199",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      
      color_code: "#FFBB0099",
    },
    
  ],
};

// Experience Page
const experience = {
  title: "Internships",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Internships",
      work: true,
      experiences: [
        {
          title: "Web Developer",
          company: "Div Hospitality",
          company_url: "http://divhospitality.com",
          logo_path: "div.jpeg",
          duration: "May 2023 - Aug 2023 ",
          location: "Ajmer , Rajasthan",
          description:
            "During my internship at Div Hospitality, I spearheaded the development of a full-stack website, surpassing previous attempts by utilizing my diverse skill set in programming languages. Employing HTML, CSS, and JavaScript, I created an engaging user interface, while integrating artificial intelligence techniques expedited development and enhanced functionality. This innovative approach not only optimized content organization and personalized recommendations but also significantly improved user satisfaction and engagement metrics. My experience underscores my ability to tackle complex challenges, collaborate effectively, and leverage cutting-edge technologies to deliver impactful solutions in dynamic environments.",
          color: "#000000",
        },
        {
          title: "Software Developer",
          company: "Perfect Publicity",
          company_url: "https://legatohealthtech.com/",
          logo_path: "perfect.jpeg",
          duration: "Nov 2023 - Jan 2024",
          location: "Chittorgarh, Rajasthan",
          description:
            "I undertook the development of a bespoke billing platform to streamline their operations. Understanding the need for convenience and efficiency, I crafted a comprehensive web form tailored precisely to their requirements. Leveraging a range of libraries and tools, I ensured a seamless user experience for the client, eliminating the need for the Ad Manager to carry physical bill booklets everywhere. This solution not only addressed their immediate needs but also enhanced their workflow, demonstrating my commitment to delivering tailored and effective digital solutions for my clients.",
          color: "#0879bf",
        },
        {
          title: "Web Developer",
          company: "Virasat Avam Vikas Sansthan",
          logo_path: "twohorse.png",
          duration: "May 2023 - Present",
          location: "Chittorgarh, Rajasthan",
          description:
            "I am making a Full Stack , complete responsive website for the organization and my approach is to provide a forever rembering experince whenever someone visits the website . The was supposed to be completed by my side by 9 Aug 2024 ",
          color: "#9b1578",
        },
       
      ],
    },
    {
      title: "Works",
      experiences: [
        {
          title: "Web Developer",
          company: "Div Hospitality",
          company_url: "http://divhospitality.com",
          logo_path: "div.jpeg",
          duration: "May 2023 - Aug 2023 ",
          location: "Ajmer , Rajasthan",
          description:
            "During my internship at Div Hospitality, I spearheaded the development of a full-stack website, surpassing previous attempts by utilizing my diverse skill set in programming languages. Employing HTML, CSS, and JavaScript, I created an engaging user interface, while integrating artificial intelligence techniques expedited development and enhanced functionality. This innovative approach not only optimized content organization and personalized recommendations but also significantly improved user satisfaction and engagement metrics. My experience underscores my ability to tackle complex challenges, collaborate effectively, and leverage cutting-edge technologies to deliver impactful solutions in dynamic environments.",
          color: "#000000",
        },
        {
          title: "Software Developer",
          company: "Perfect Publicity",
          company_url: "https://legatohealthtech.com/",
          logo_path: "perfect.jpeg",
          duration: "Nov 2023 - Jan 2024",
          location: "Chittorgarh, Rajasthan",
          description:
            "I undertook the development of a bespoke billing platform to streamline their operations. Understanding the need for convenience and efficiency, I crafted a comprehensive web form tailored precisely to their requirements. Leveraging a range of libraries and tools, I ensured a seamless user experience for the client, eliminating the need for the Ad Manager to carry physical bill booklets everywhere. This solution not only addressed their immediate needs but also enhanced their workflow, demonstrating my commitment to delivering tailored and effective digital solutions for my clients.",
          color: "#0879bf",
        },
        {
          title: "Web Developer",
          company: "Virasat Avam Vikas Sansthan",
          logo_path: "twohorse.png",
          duration: "May 2023 - Present",
          location: "Chittorgarh, Rajasthan",
          description:
            "I am making a Full Stack , complete responsive website for the organization and my approach is to provide a forever rembering experince whenever someone visits the website . The was supposed to be completed by my side by 9 Aug 2024 . ",
          color: "#9b1578",
        },
        {
          title: "Web Development",
          company: "Apple Vision Clone",
          logo_path: "vision.png",
          duration: "May 2024 ",
          location: "Jaipur, Rajasthan",
          description:
            "I Developed the Apple Vision Clone website using HTML , CSS , JavaScript and tried to provide the user more smooth and user friendly interface . The main motive is to dvelope the clone of the website is to learn how the website of apple can be make and i tried to rectify some issues in the orginal apple vision website in my apple vision clone website .",
          color: "#9b1578",
        },
        {
          title: "Web Development",
          company: "Elegance { A Salon Website }",
          logo_path: "elegance.jpeg",
          duration: "May 2024 ",
          location: "Jaipur, Rajasthan",
          description:
            " I developed a Full Stack Website for the Salon and completely responsive which provide a major feature of booking slot in the salon and i tried to provide the users a aesthetic interface and a very user friendly experience . ",
          color: "#9b1578",
        },
        {
          title: "Web Development",
          company: "Rayban { A Complete Animated Website  } not a clone ",
          logo_path: "ban.png",
          duration: "June 2024 ",
          location: "Jaipur, Rajasthan",
          description:
            "I developed a completely Animated website which provide user extremely attractive experince the motive of this work is to make a animating website that will attract the user while using the website also it is a user friendly website link :- rayban-abhay.vercel.app [ I hope you will like it ] , NOTE : This is not a responsive website . ",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Leader to Guide the team",
          company: "TECH STARTUP EVENT by AIC MUJ",
          company_url: "https://jaipur.manipal.edu",
          logo_path: "jaipur.png",
          duration: "2022",
          location: "Jaipur, Rajasthan",
          description:
            "In this event we were conducting a event like to create a fiar of startups where the entreprenures can showcase their product or work in the stalls to the students or parents or people gathered in the fair , and there were some sponsors who were willing to connect to a startup with good apprach of work and having a quality product . The spponsor were supposed to provide sponsorships on make invesetment to grow the startup and have some equity or shares to those startups who are doing really good and have potetial to grow more and faster . ",
          color: "#4285F4",
        },
        {
          title: "Organiser of Competition ",
          company: "Virasat Avam Vikas Sansthan",
          company_url: "https://drive.google.com/drive/folders/1Dpu17LaNvL7jp4PBC2dyXAs_-FQqjFf4?usp=sharing",
          logo_path: "twohorse.png",
          duration: " June - 2024",
          location: "Chittorgarh, Rajasthan",
          description:
            "On the ocassion of veer Sheromani Maharana Pratap Jayanti we conducted a competition , around 400+ people gathered to participate the quiz competition and the Prize were distributed among 8 runner up of the Competition and awarded by Govt certified certificates and cash prize . ",
          color: "#D83B01",
        },
        {
          title: "Hackathon Organiser",
          company: "by :- DigiBox ",
          company_url: "",
          logo_path: "box.jpeg",
          duration: "May 2024",
          location: "Jaipur, Rajasthan",
          description:
            "My responsibility for this program was to organise the event smoothly and successfully .",
          color: "#000000",
        },
        
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "",
  description: "",
  avatar_image_path: "",
};

const publications = {
  data: [],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "wow.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "linkedin",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing , Vsit my Linkedin to know more and connect with me ☺️ .",
    link: "https://www.linkedin.com/in/abhay-pratap-singh-sanchora-6b11b4293/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "164/4 Gandhinagar, Chittorgarh, Rajasthan",
    locality: "Gandhinagar",
    country: "INDIA",
    region: "Rajasthan",
    postalCode: "312001",
    streetAddress: "Gandhinagar sector 4 ",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/552%2F2,+Sector+3,+Gandhi+Nagar,+Chittorgarh,+Rajasthan+312001/@24.881038,74.6362374,18.2z/data=!4m6!3m5!1s0x3968a0609ab3eed3:0xbda7d266319775f1!8m2!3d24.880702!4d74.6363606!16s%2Fg%2F11s7y_tbrk?entry=ttu",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
